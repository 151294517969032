/*=== About us header===*/
.about-header {
  margin: 5% 0;
}
.about-header .about-header-wrapper {
  text-align: center;
}
.about-header .about-header-wrapper h2 {
  font-size: 5rem;
}

/*=== Our Mission ===*/
.mission {
  margin: 5% 10%;
}
.mission .mission-wrapper {
  display: flex;
}
.mission .mission-wrapper .mission-images {
  width: 50%;
}
.mission .mission-wrapper .mission-images .miss-image-wrapper {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0% 50% 50% 50%;
}
.mission .mission-wrapper .mission-images .miss-image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.mission .mission-wrapper .mission-texts {
  width: 50%;
}
.mission .mission-wrapper .mission-texts h1 {
  color: inherit;
}
/* ======frequently asked questions stylings====== */
.faqmainSection {
  margin: 4rem 0;
}
.faqmainSection .faqsection .faqcontainer {
  margin: 0 15%;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper {
  padding: 6rem;
  padding-bottom: 4rem;
  border-radius: 23px;
  background: linear-gradient(to bottom right, rgb(11, 30, 41), rgb(9, 20, 26)) !important;
  transition: 0.3s;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqtitle {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #f6f6f6;
  text-transform: uppercase;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle .faqangleright {
  fill: aliceblue;
  height: 20px;
  width: 20px;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle .faqText, .faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle i {
  color: #f6f6f6;
  font-size: 20px;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: 1.4;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdroptitle .angleDown {
  transform: rotate(90deg);
  display: unset;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdropcontents {
  display: none;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqdropcontents .faqdroptexts {
  color: #f6f6f6;
  line-height: 1.5;
  margin-bottom: 10px;
}
.faqmainSection .faqsection .faqcontainer .faqwrapper .faqflex .faqdrop .faqShow {
  display: unset;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

/* ======newsletter subscribe stylings====== */
.secNewsletter {
  margin-top: 150px;
  margin-bottom: 150px;
}
.secNewsletter .newsletter .newslettercontainer {
  margin: 0 5%;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter {
  background-size: 40%, 60%;
  background-position: left top, right bottom;
  background-image: url(../../assets/svg/glow-blue-02.svg), url(../../assets/svg/glow-purple-02.svg);
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  max-width: 1216px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex {
  display: flex;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  border-radius: 0.75rem;
  background-color: rgb(9, 20, 26);
  background-position: 100%;
  background-repeat: no-repeat;
  flex-direction: row;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  background-image: url(../../assets/svg/envelop.svg);
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts {
  width: 50%;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts .subNewstitle {
  font-size: 2rem;
  line-height: 2.25rem;
  color: rgb(246, 246, 246);
  font-weight: 700;
  letter-spacing: 1.5px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subNewstexts .subNewsTextsp {
  margin-top: 0.75rem;
  color: rgb(246, 246, 246);
  letter-spacing: 0.5px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform {
  position: relative;
  width: 50%;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex {
  display: flex;
  overflow: hidden;
  border-radius: 0.75rem;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex .subnewsinput {
  outline: none;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: rgba(49, 49, 49, 0.707);
  color: rgb(218, 218, 218);
  height: 3.5rem;
  margin-right: -10px;
  width: 300px;
}
.secNewsletter .newsletter .newslettercontainer .subnewsletter .subnewsFlex .subnewsform .subNewsFormf .subinputsFlex .subnewsButton {
  cursor: pointer;
  border-radius: 0.75rem;
  background-color: cyan;
  padding: 10px 20px;
  font-weight: 600;
  outline: none;
  border: none;
}/*# sourceMappingURL=abtsec.css.map */
.cheader {
  padding-top: 40px;
}
.cheader .header-container {
  margin: 0 5%;
}
.cheader .header-container .header-flex {
  display: flex;
}
.cheader .header-container .header-flex .header-left {
  width: 50%;
}
.cheader .header-container .header-flex .header-left .header-left-flex {
  display: flex;
  flex-direction: column;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-top {
  display: flex;
  justify-content: space-between;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-top .header-brand {
  width: 30%;
  display: flex;
  align-items: center;
  align-content: center;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-top .header-brand h1 {
  margin: 0;
  font-size: 3rem;
  color: #0088f0;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-top .header-brand img {
  height: 35px;
  width: 35px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 20px;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-top .header-brand-texts {
  width: 50%;
  padding-top: 10px;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-top .header-brand-texts p {
  font-size: 0.9rem;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom {
  margin-top: 10px;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom .header-nav ul {
  display: flex;
  padding-left: 0;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom .header-nav ul .header-nav-links {
  padding: 5px;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom .header-nav ul .header-nav-links a {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #eaeaea;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom .header-nav ul .header-nav-links a:hover {
  background-color: #505050;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom .header-nav ul .header-nav-links .selected {
  background-color: #0088f0;
}
.cheader .header-container .header-flex .header-left .header-left-flex .header-left-bottom .header-nav ul .header-nav-links .selected:hover {
  background-color: #0088f0 !important;
}
.cheader .header-container .header-flex .header-right {
  padding-top: 25px;
  width: 50%;
}
.cheader .header-container .header-flex .header-right .header-right-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-search .header-search-form {
  position: relative;
  border: 1px solid var(--text-primary);
  border-radius: 5px;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-search .header-search-form .header-search-input {
  outline: none;
  border: none;
  padding: 10px;
  padding-left: 35px;
  background-color: transparent;
  color: var(--theme-color);
  width: 200px;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-search .header-search-form .header-search-input:focus ~ .search-input-placeholder,
.cheader .header-container .header-flex .header-right .header-right-flex .header-search .header-search-form .header-search-input:not([value=""]) ~ .search-input-placeholder {
  visibility: hidden;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-search .header-search-form .header-search-icon {
  position: absolute;
  fill: var(--text-primary);
  top: 10px;
  left: 10px;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-search .header-search-form .search-input-placeholder {
  position: absolute;
  left: 35px;
  top: 5px;
  pointer-events: none;
  color: #9d9d9d;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links {
  margin-left: 20px;
  display: flex;
  align-content: center;
  align-items: center;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links a {
  margin-bottom: -10px;
  margin-right: 15px;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links a .header-mail-icon {
  fill: #9d9d9d;
  cursor: pointer;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links a .header-mail-icon:hover {
  fill: var(--text-primary);
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links .theme-controls {
  display: flex;
  align-items: center;
  align-content: center;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links .theme-controls svg {
  stroke: #9d9d9d;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links .theme-controls svg:hover {
  stroke: var(--text-primary);
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links .theme-controls .themeToggle {
  height: 20px;
  width: 35px;
  border-radius: 30px;
  background-color: #9d9d9d;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}
.cheader .header-container .header-flex .header-right .header-right-flex .header-right-links .theme-controls .themeToggle .toggleButton {
  height: 16px;
  width: 16px;
  margin: 2px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0);
  transition: all 0.2s ease-in-out 0s;
}/*# sourceMappingURL=cheader.css.map */
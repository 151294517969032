/*=== About us header===*/
.about-header{
    margin: 5% 0;
    .about-header-wrapper{
        text-align: center;
        h2{
            font-size: 5rem;
        }
    }
}

/*=== Our Mission ===*/
.mission{
    margin: 5% 10%;
    .mission-wrapper{
        display: flex;
        .mission-images{
            width: 50%;
            .miss-image-wrapper{
                width: 250px;
                height: 250px;
                margin: 0 auto;
                overflow: hidden;
                border-radius: 0% 50% 50% 50%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .mission-texts{
            width: 50%;
            .sec-text{
            }
            h1{
                color: inherit;
            }
            p{
            }
        }
    }
}

/* ======frequently asked questions stylings====== */
.faqmainSection{
    margin: 4rem 0;
    .faqsection{
        .faqcontainer{
            margin: 0 15%;
            .faqwrapper{
                padding: 6rem;
                padding-bottom: 4rem;
                border-radius: 23px;
                background: linear-gradient(to bottom right,rgb(11, 30, 41),rgba(9,20,26,1))!important;
                transition: 0.3s;
                .faqflex{
                    .faqtitle{
                        margin-bottom: 40px;
                        text-align: center;
                        font-weight: 700;
                        font-size: 32px;
                        color: #f6f6f6;
                        text-transform: uppercase;
                    }
                    .faqdrop{
                        .faqdroptitle{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            cursor: pointer;
                            .faqangleright{
                                fill: aliceblue;
                                height: 20px;
                                width: 20px;
                            }
                            .faqText, i{
                                color: #f6f6f6;
                                font-size: 20px;
                                font-weight: 700;
                                user-select: none;
                                letter-spacing: 1.4;
                            }
                            .angleDown{
                                transform: rotate(90deg);
                                display: unset;
                                transition-property: all;
                                transition-timing-function: cubic-bezier(.4,0,.2,1);
                                transition-duration: .15s;
                            }
                        }
                        .faqdropcontents{
                            display: none;
                            .faqdroptexts{
                                color: #f6f6f6;
                                line-height: 1.5;
                                margin-bottom: 10px;
                            }
                        }
                        .faqShow{
                            display: unset;
                            transition-property: all;
                            transition-timing-function: cubic-bezier(.4,0,.2,1);
                            transition-duration: .15s;
                        }
                    }
                }
            }
        }
    }
}

/* ======newsletter subscribe stylings====== */
.secNewsletter{
    margin-top: 150px;
    margin-bottom: 150px;
    .newsletter{
        .newslettercontainer{
            margin: 0 5%;
            .subnewsletter{
                background-size: 40%,60%;
                background-position: left top,right bottom;
                background-image: url(../../assets/svg/glow-blue-02.svg),url(../../assets/svg/glow-purple-02.svg);
                background-repeat: no-repeat;
                margin-left: auto;
                margin-right: auto;
                max-width: 1216px;
                .subnewsFlex{
                    display: flex;
                    grid-gap: 2.5rem;
                    gap: 2.5rem;
                    border-radius: 0.75rem;
                    background-color: rgba(9,20,26,1);
                    background-position: 100%;
                    background-repeat: no-repeat;
                    flex-direction: row;
                    padding-top: 3rem;
                    padding-bottom: 3rem;
                    padding-left: 5rem;
                    padding-right: 5rem;
                    background-image: url(../../assets/svg/envelop.svg);
                    .subNewstexts{
                        width: 50%;
                        .subNewstitle{
                            font-size: 2rem;
                            line-height: 2.25rem;
                            color: rgba(246,246,246,1);
                            font-weight: 700;
                            letter-spacing: 1.5px;
                        }
                        .subNewsTextsp{
                            margin-top: 0.75rem;
                            color: rgba(246,246,246,1);
                            letter-spacing: .5px;
                        }
                    }
                    .subnewsform{
                        position: relative;
                        width: 50%;
                        .subNewsFormf{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            .subinputsFlex{
                                display: flex;
                                overflow: hidden;
                                border-radius: 0.75rem;
                                .subnewsinput{
                                    outline: none;
                                    border: none;
                                    padding-left: 1rem;
                                    padding-right: 1rem;
                                    font-size: 1rem;
                                    line-height: 1.5rem;
                                    background-color: rgba(49, 49, 49, 0.707);
                                    color: rgb(218, 218, 218);
                                    height: 3.5rem;
                                    margin-right: -10px;
                                    width: 300px;
                                }
                                .subnewsButton{
                                    cursor: pointer;
                                    border-radius: 0.75rem;
                                    background-color: cyan;
                                    padding: 10px 20px;
                                    font-weight: 600;
                                    outline: none;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
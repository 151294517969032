.sidebar{
    height: 100vh;
    overflow: hidden;
    background-color: var(--theme-bg-color);
    border-right: 1px solid var(--theme-bg-color);
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.45) 100%);
    // -webkit-backdrop-filter: saturate(3);
    // backdrop-filter: saturate(3);
    backdrop-filter: blur(20px);
    // -webkit-backdrop-filter: blur(20px);
    .sideWrapper{
        .sideBrand{
            width: min-content;
            margin: 0 auto;
            display: flex;
            align-items: center;
            align-content: center;
            margin-top: 10px;
            h2{
                text-transform: uppercase;
            }
        }
        .sidenav{
            margin-top: 60px;
            .unlistnav{
                .side-link{
                    padding: 5px 20px;
                    display: flex;
                    align-items: center !important;
                    cursor: pointer;
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    .navIcs{
                        height: 25px;
                        width: 25px;
                        margin-right: 30px;
                        fill: var(--color);
                    }
                    &:hover{
                        background-color: var(--hover-menu-bg);
                    }
                }
                .active{
                    background-color: var(--hover-menu-bg);
                }
            }
        }
    }
}

.hside{
    animation-name: hslide;
    animation-duration: .5s;
    width: 0;
    overflow: hidden;
}

.shside{
    animation-name: shslide;
    animation-duration: .5s;
    width: 250px;
}

@keyframes shslide {
    from {width: 0;}
    to {width: 250px;}
}

@keyframes hslide {
    from {width: 250px;}
    to {width: 0px;}
}
/*=-=-=-=-=-=contact page=-=-=-=-=-=-=*/
.ccontact {
  margin: 5% 10%;
}
.ccontact .contact-wrapper .contact-header {
  text-align: center;
  font-size: 6rem;
}
.ccontact .contact-wrapper .formNotify {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  background-color: #ff7c7c;
  border-left: 5px solid red;
  width: 50%;
  margin-bottom: 20px;
}
.ccontact .contact-wrapper .formNotifySucc {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  background-color: #43b34d;
  border-left: 5px solid #00ff88;
  width: 50%;
  margin-bottom: 20px;
}
.ccontact .contact-wrapper .contFormSec {
  margin-top: 3rem;
  margin-left: 25px;
}
.ccontact .contact-wrapper .contFormSec form .cont-group {
  margin-bottom: 40px;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp {
  position: relative;
  width: 94%;
  margin: 5px 0px;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: 1px solid rgb(211, 211, 211);
  padding: 10px 13px;
  box-shadow: none !important;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  color: #505050;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:not([value=""]) {
  background-color: rgb(255, 219, 166);
}
.ccontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 10px;
  transition: 0.2s ease all;
  opacity: 0.5;
  color: rgb(95, 95, 95);
}
.ccontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:not([value=""]) ~ .floating-label,
.ccontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:focus ~ .floating-label {
  top: 0px;
  left: 8px;
  font-size: 13px;
  opacity: 1;
  color: var(--theme-color);
}
.ccontact .contact-wrapper .contFormSec form .cont-group .cont-gr-flex {
  width: 97%;
  display: flex;
  justify-content: space-between;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .cont-gr-flex .contPut {
  width: 46%;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row {
  margin-bottom: 22px;
  justify-content: space-between;
  margin: 15px 0px;
  width: 98%;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row {
  width: 97%;
  position: relative;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px dashed var(--text-primary);
  border-radius: 5px;
  width: 100%;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content {
  display: flex;
  align-items: center;
  position: relative;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .icon {
  margin-right: 15px;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .icon img {
  max-width: 100%;
  height: auto;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label {
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 22px;
  color: var(--theme-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 1200px;
  overflow-x: scroll;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label::-webkit-scrollbar {
  scrollbar-color: rgb(35, 42, 46);
  width: 5px;
  max-height: 3px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0);
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #eaeaea;
  background-color: #505050;
  margin-right: 3px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list:hover {
  background-color: #797979;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap {
  display: flex;
  align-items: center;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap .button {
  background: #2ac6ff;
  border-radius: 4px;
  padding: 9px 23px 11px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
}
.ccontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .file-field {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 70%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.ccontact .contact-wrapper .contFormSec form .contact-btn {
  border-radius: 4px;
  padding: 13px 54px 15px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
.ccontact .contact-wrapper .contFormSec form .contact-btn:hover {
  color: rgb(109, 216, 255);
  background-color: rgb(233, 233, 233);
  transition: 0.3s;
}/*# sourceMappingURL=creports.css.map */
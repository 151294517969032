.header{
    .container{
        .hFlex{
            display: flex;
            align-items: flex-start;
            .contarea{
                flex-grow: 1;
                .nav{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-grow: 1;
                    height: 65px;
                    background-color: var(--theme-bg-color);
                    border-bottom: 1px solid var(--theme-bg-color);
                    padding: 0 20px;
                    position: relative;
                    .left{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-grow: 1;
                        .navControls{
                            height: 30px;
                            width: 30px;
                            padding: 5px;
                            border-radius: 50%;
                            background-color: #0000007c;
                            position: relative;
                            margin-right: 20px;
                            .navBars{
                                height: 25px;
                                width: 25px;
                                cursor: pointer;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                fill: #fff;
                            }
                        }
                        .searchForm{
                            flex-grow: 1;
                            position: relative;
                            .searchInput{
                                outline: none;
                                border: none;
                                padding: 10px;
                                border-radius: 5px;
                                width: 70%;
                                color: #000000;
                            }
                            .searchformbtn{
                                outline: none;
                                background-color: transparent;
                                border: none;
                                position: absolute;
                                right: 30%;
                                top: 10px;
                                .searchLens{
                                    height: 15px;
                                    width: 15px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .right{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        margin-right: 2%;
                        .themeControls{
                            position: relative;
                            .thmoon, .thsun{
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 25px;
                                width: 25px;
                                cursor: pointer;
                                margin-right: 30px;
                                stroke: var(--color);
                            }
                        }
                        .msgIc, .bellIc, svg, .themeControls{
                            height: 25px;
                            width: 25px;
                            cursor: pointer;
                            margin-right: 30px;
                            stroke: var(--color);
                        }
                        .notifwrapper{
                            position: relative;
                            height: 25px;
                            width: 25px;
                            cursor: pointer;
                            margin-right: 30px;
                            .bellIc, .msgIc{
                                fill: var(--color);
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                            .notfBars{
                                background-color: #ff0000;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                position: absolute;
                                top: -10px;
                                div{
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    span{
                                        font-size: 12px;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        font-weight: 700;
                                    }
                                }
                            }
                            .hidenofifBars{
                                display: none;
                            }
                        }
                        .havatar{
                            height: 30px;
                            width: 30px;
                            cursor: pointer;
                            border-radius: 50%;
                            overflow: hidden;
                            .avatarHolder{
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                                fill: var(--color);
                            }
                        }
                    }
                }
                .hidenavDropdown{
                    display: none;
                }
                .navDropDown{
                    position: absolute;
                    height: min-content;
                    max-height: calc(100vh - 65px);
                    width: 250px;
                    right: 100px;
                    z-index: 1000;
                    background-color: #3d3d3d;
                    border-radius: 5px;
                    ul{
                        overflow: hidden;
                        li{
                            cursor: pointer;
                            padding: 5px;
                            &:hover{
                                background-color: #646464;
                            }
                            h2{
                                font-size: 15px;
                            }
                            p{
                                font-size: 10px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                max-height: 4rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-wrap: break-word;
                                word-break: break-word;
                            }
                        }
                    }
                }
                .playarea{
                    height: calc(100vh - 65px);
                    overflow-y: scroll;
                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: #2f323900;
                    }
                }
            }
        }
    }
}
.dashboard {
  padding: 15px;
}
.dashboard .topOne {
  display: flex;
  justify-content: space-between;
}
.dashboard .topOne .wetopleft {
  flex-grow: 1;
  width: 30%;
  margin-right: 20px;
}
.dashboard .topOne .wetopleft .adminwelcome {
  display: flex;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 10px;
  padding-bottom: 20px;
  transition: 0.3s ease;
  background-color: var(--theme-bg-color);
  margin-bottom: 1.5rem;
}
.dashboard .topOne .wetopleft .adminwelcome:hover {
  transform: scale(1.02);
}
.dashboard .topOne .wetopleft .adminwelcome .welProfilePic {
  position: relative;
  width: 30% !important;
}
.dashboard .topOne .wetopleft .adminwelcome .welProfilePic .adminProfile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
}
.dashboard .topOne .wetopleft .adminwelcome .welProfilePic .adminProfile .addPr {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  fill: var(--color);
}
.dashboard .topOne .wetopleft .adminwelcome .welRtexts {
  flex-grow: 1;
  width: 70%;
}
.dashboard .topOne .wetopleft .adminwelcome .welRtexts h2 {
  font-size: 1.9rem;
  margin-bottom: 15px;
}
.dashboard .topOne .wetopleft .adminwelcome .welRtexts .adminLogout {
  padding: 10px 20px;
  outline: none;
  border: none;
  background-color: #1e92ff;
  margin-top: 20px;
  color: var(--theme-color);
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
}
.dashboard .topOne .wetopleft .wetopbtm {
  margin-top: 10px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo {
  padding: 15px 10px;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  width: 48%;
  background-color: var(--theme-bg-color);
  display: flex;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone:hover, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo:hover {
  transform: scale(1.02);
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrTexts, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrTexts {
  flex-grow: 1;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrTexts p, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrTexts p {
  font-size: 15px;
  margin-bottom: 10px;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrTexts .lweekstats, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrTexts .lweekstats {
  display: flex;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrTexts .lweekstats p, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrTexts .lweekstats p {
  font-size: 12px;
  margin-right: 20px;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrTexts .lweekstats p:last-child, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrTexts .lweekstats p:last-child {
  color: rgb(29, 255, 168);
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrTexts .lweekstats span, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrTexts .lweekstats span {
  background-color: rgb(29, 255, 168);
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrIcon, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrIcon {
  position: relative;
  width: 30%;
}
.dashboard .topOne .wetopleft .wetopbtm .wetbtone .tbrIcon .MainIctbr, .dashboard .topOne .wetopleft .wetopbtm .wetbttwo .tbrIcon .MainIctbr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 40px;
  fill: var(--theme-color) !important;
}
.dashboard .topOne .crimeGraphic {
  width: 65%;
  padding: 20px;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  overflow: hidden;
  background-color: var(--theme-bg-color);
}
.dashboard .topOne .crimeGraphic:hover {
  transform: scale(1.02);
}
.dashboard .toptwo {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.dashboard .toptwo .ttwoleft {
  width: 34%;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 10px;
  padding-bottom: 20px;
  transition: 0.3s ease;
  background-color: var(--theme-bg-color);
  margin-bottom: 1.5rem;
}
.dashboard .toptwo .ttwoleft:hover {
  transform: scale(1.02);
}
.dashboard .toptwo .ttworight {
  display: flex;
  gap: 1rem;
  flex-grow: 1;
}
.dashboard .toptwo .ttworight .activeadmins {
  width: 50%;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 10px;
  padding-bottom: 20px;
  transition: 0.3s ease;
  background-color: var(--theme-bg-color);
  margin-bottom: 1.5rem;
}
.dashboard .toptwo .ttworight .activeadmins:hover {
  transform: scale(1.02);
}
.dashboard .toptwo .ttworight .activeclients {
  width: 50%;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 10px;
  padding-bottom: 20px;
  transition: 0.3s ease;
  background-color: var(--theme-bg-color);
  margin-bottom: 1.5rem;
}
.dashboard .toptwo .ttworight .activeclients:hover {
  transform: scale(1.02);
}/*# sourceMappingURL=dashboard.css.map */
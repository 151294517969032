.cheader{
    padding-top: 40px;
    .header-container{
        margin: 0 5%;
        .header-flex{
            display: flex;
            .header-left{
                width: 50%;
                .header-left-flex{
                    display: flex;
                    flex-direction: column;
                    .header-left-top{
                        display: flex;
                        justify-content: space-between;
                        .header-brand{
                            width: 30%;
                            display: flex;
                            align-items: center;
                            align-content: center;
                            h1{
                                margin: 0;
                                font-size: 3rem;
                                color: #0088f0;
                            }
                            img{
                                height: 35px;
                                width: 35px;
                                object-fit: cover;
                                margin: 20px;
                            }
                        }
                        .header-brand-texts{
                            width: 50%;
                            padding-top: 10px;
                            p{
                                font-size: .9rem;
                            }
                        }
                    }
                    .header-left-bottom{
                        margin-top: 10px;
                        .header-nav{
                            ul{
                                display: flex;
                                padding-left: 0;
                                .header-nav-links{
                                    padding: 5px;
                                    a{
                                        padding: 8px 15px;
                                        border-radius: 23px;
                                        font-size: .9rem;
                                        text-decoration: none;
                                        color: #eaeaea;
                                        &:hover{
                                            background-color: #505050;
                                        }
                                    }
                                    .selected{
                                        background-color: #0088f0;
                                        &:hover{
                                            background-color: #0088f0 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .header-right{
                padding-top: 25px;
                width: 50%;
                .header-right-flex{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .header-search{
                        .header-search-form{
                            position: relative;
                            border: 1px solid var(--text-primary);
                            border-radius: 5px;
                            .header-search-input{
                                outline: none;
                                border: none;
                                padding: 10px;
                                padding-left: 35px;
                                background-color: transparent;
                                color: var(--theme-color);
                                width: 200px;
                            }
                            .header-search-input:focus~.search-input-placeholder,
                            .header-search-input:not([value=""])~.search-input-placeholder{
                                visibility: hidden;
                                // transition: 0.2s ease all;
                            }
                            .header-search-icon{
                                position: absolute;
                                fill: var(--text-primary);
                                top: 10px;
                                left: 10px;
                            }
                            .search-input-placeholder{
                                position: absolute;
                                left: 35px;
                                top: 5px;
                                pointer-events: none;
                                color: #9d9d9d;
                            }
                        }
                    }
                    .header-right-links{
                        margin-left: 20px;
                        display: flex;
                        align-content: center;
                        align-items: center;
                        a{
                            margin-bottom: -10px;
                            margin-right: 15px;
                            .header-mail-icon{
                                fill: #9d9d9d;
                                cursor: pointer;
                                &:hover{
                                    fill: var(--text-primary);
                                }
                            }
                        }
                        .theme-controls{
                            display: flex;
                            align-items: center;
                            align-content: center;
                            svg{
                                stroke: #9d9d9d;
                                &:hover{
                                    stroke: var(--text-primary);
                                }
                            }
                            .themeToggle{
                                height: 20px;
                                width: 35px;
                                border-radius: 30px;
                                background-color: #9d9d9d;
                                margin: 0 10px;
                                position: relative;
                                cursor: pointer;
                                .toggleButton{
                                    height: 16px;
                                    width: 16px;
                                    margin: 2px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    transform: translate(0);
                                    transition: all 0.2s ease-in-out 0s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
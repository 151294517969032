:root {
    --background: #171c28;
    --text-primary: rgb(230, 230, 230);
    transition: 0.5s;
  }
  [data-theme='clight'] {
    --background: #ffffff;
    --text-primary: #444452;
    transition: 0.5s;
  }
  
  [data-theme='cdark'] {
    --background: #171c28;
    --text-primary: rgb(230, 230, 230);
    transition: 0.5s;
  }
  *{
      padding: 0;
      margin: 0;
  }
  html,body{
      border-style: border-box;
      font-family: Apercu,source sans pro,system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-image: url(../../assets/svg/bg-header.svg);
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: 100% 215px;
      background-color: #3a3a3a;
      width: 100%;
      overflow-x: hidden;
      color: var(--text-primary);
      text-rendering: optimizeLegibility;
      text-size-adjust: 100%;
      position: relative;
      z-index: 0 !important;
      scroll-behavior: smooth;
      line-height: 1.5;
  }
  li {
      list-style: none;
  }
  a{
      text-decoration: none;
  }
  //hide increment and decrement in number input types
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  //vertical scrollbar
  body::-webkit-scrollbar {
    width: 5px;
    background-color: #2f323900;
  }
  @media(max-width: 600px){
    *{
        -ms-overflow-style: none;
    }
    body::-webkit-scrollbar {
        scrollbar-color: transparent transparent;
    }
  }
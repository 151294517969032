@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root{
    --color: #eaeaea;
}
:root {
  --background: #9affdf;
  --color: #444452;
  --back-boxes: rgb(223, 220, 255);
  --back-related: #c8ffe4;

  --modal-bg: #000;

  --theme-bg-color: rgba(16 18 27 / 40%);
  --border-color: rgba(113 119 144 / 25%);
  --theme-color: #f9fafb;
  --inactive-color: rgb(113 119 144 / 78%);
  --body-font: "Poppins", sans-serif;
  --hover-menu-bg: rgba(12 15 25 / 30%);
  --content-title-color: #999ba5;
  --content-bg: rgb(146 151 179 / 13%);
  --button-inactive: rgb(249 250 251 / 55%);
  --dropdown-bg: #21242d;
  --dropdown-hover: rgb(42 46 60);
  --popup-bg: rgb(22 25 37);
  --search-bg:  #14162b;
  --overlay-bg: rgba(36, 39, 59, 0.3);
  --scrollbar-bg: rgb(1 2 3 / 40%);
}
[data-theme='light'] {
  --background: #9affdf;
  --color: #444452;
  --back-related: #c8ffe4;
  --toggle-transform: translate(0);
  transition: 0.5s;
  --modal-bg: #1effbc;
  --theme-bg-color: rgba(255, 255, 255, 0.555);
  --theme-color: #3c3a3a;
  --inactive-color: #333333;
   --button-inactive: #3c3a3a;
  --search-bg: rgb(255 255 255 / 31%);
  --dropdown-bg: #f7f7f7;
  --overlay-bg: rgb(255 255 255 / 30%);
  --dropdown-hover: rgb(236 236 236);
  --border-color: rgb(255 255 255 / 35%);
  --popup-bg: rgb(255 255 255);
  --hover-menu-bg: rgba(255 255 255 / 35%);
  --scrollbar-bg: rgb(255 253 253 / 57%);
  --content-title-color: --theme-color;
}

[data-theme='dark'] {
  --background: #242424;
  --color: rgb(230, 230, 230);
  --back-related: #313131;
  --toggle-transform: translate(120%);
  transition: 0.5s;
  --modal-bg: #161616;
  --theme-bg-color: rgba(16, 18, 27, 0.514);
  --border-color: rgba(113 119 144 / 25%);
  --theme-color: #f9fafb;
  --inactive-color: rgb(113 119 144 / 78%);
  --body-font: "Poppins", sans-serif;
  --hover-menu-bg: rgba(12 15 25 / 30%);
  --content-title-color: #999ba5;
  --content-bg: rgb(146 151 179 / 13%);
  --button-inactive: rgb(249 250 251 / 55%);
  --dropdown-bg: #21242d;
  --dropdown-hover: rgb(42 46 60);
  --popup-bg: rgb(22 25 37);
  --search-bg:  #14162b;
  --overlay-bg: rgba(36, 39, 59, 0.3);
  --scrollbar-bg: rgb(1 2 3 / 40%);
}
*{
    padding: 0;
    margin: 0;
    color: var(--theme-color);
    transition: .3s !important;
    outline: none;
    box-sizing: border-box;
}
html,body{
    border-style: border-box;
    font-family: var(--body-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    overflow-x: hidden;
    color: var(--theme-color);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    position: relative;
    z-index: 0 !important;
    scroll-behavior: smooth;
    line-height: 1.5;
    transition: .3s;
}
li {
    list-style: none;
}
a{
    text-decoration: none;
}
//hide increment and decrement in number input types
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
//vertical scrollbar
body::-webkit-scrollbar {
  width: 5px;
  background-color: #2f323900;
}
@media(max-width: 600px){
  *{
      -ms-overflow-style: none;
  }
  body::-webkit-scrollbar {
      scrollbar-color: transparent transparent;
  }
}

.App{
  background-color: var(--background);
  backdrop-filter: blur(5px);
  transition: all 0.2s ease-in-out 0s;
}
.contents{
  transition: all 0.2s ease-in-out 0s;
  margin-right: auto;
  margin-left: auto;
}
.lightMode, .darkMode{
  stroke: var(--text-primary);
  height: 17px;
  width: 17px;
}
.hidecontents{
  display: none !important;
}

.sCounter, .secCounter, .pCounter, .eCounter{
  font-family: Inconsolata,monospace !important;
}

//cookie notification
.cookieConsent{
  width: 190px;
  background: url('../../assets/img/cookie-bg.png') center center / 100% 100% no-repeat;
  position: fixed;
  right: 0px;
  padding: 14px 64px 20px 20px;
  z-index: 98;
  left: 16px;
  bottom: 16px !important;
}
.cookieText{
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 15px;
  color: rgb(61, 61, 61);
  letter-spacing: 0.1px;
}
.cookiehiglight{
  font-style: italic;
  font-weight: 700;
}
.privacyPolicy{
  text-decoration: underline;
}

.button{
  font-size: 14px;
  line-height: 15px;
  padding: 9px 22px 10px;
  border: 1px solid rgb(0, 0, 0);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
}

table, th, td {
  border: 1px solid;
}
table {
  border-collapse: collapse;
}
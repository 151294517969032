.dashboard{
    padding: 15px;
    .topOne{
        display: flex;
        justify-content: space-between;
        .wetopleft{
            flex-grow: 1;
            width: 30%;
            margin-right: 20px;
            .adminwelcome{
                display: flex;
                // background-color: var(--content-bg);
                border-radius: 14px;
                border: 1px solid var(--theme-bg-color);
                padding: 10px;
                padding-bottom: 20px;
                transition: 0.3s ease;
                background-color: var(--theme-bg-color);
                margin-bottom: 1.5rem;
                &:hover{
                    transform: scale(1.02);
                }
                .welProfilePic{
                    position: relative;
                    width: 30% !important;
                    .adminProfile{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        height: 70px;
                        width: 70px;
                        cursor: pointer;
                        border-radius: 50%;
                        overflow: hidden;
                        padding: 2px;
                        .addPr{
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                            fill: var(--color);
                        }
                    }
                }
                .welRtexts{
                    flex-grow: 1;
                    width: 70%;
                    h2{
                        font-size: 1.9rem;
                        margin-bottom: 15px;
                    }
                    .adminLogout{
                        padding: 10px 20px;
                        outline: none;
                        border: none;
                        background-color: #1e92ff;
                        margin-top: 20px;
                        color: var(--theme-color);
                        cursor: pointer;
                        border-radius: 5px;
                        font-size: 1rem;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
        
                }
            }
            .wetopbtm{
                margin-top: 10px;
                display: flex;
                gap: 1rem;
                justify-content: space-between;
                margin-bottom: 1rem;
                .wetbtone, .wetbttwo{
                    padding: 15px 10px;
                    border-radius: 14px;
                    border: 1px solid var(--theme-bg-color);
                    width: 48%;
                    background-color: var(--theme-bg-color);
                    display: flex;
                    &:hover{
                        transform: scale(1.02);
                    }
                    .tbrTexts{
                        flex-grow: 1;
                        p{
                            font-size: 15px;
                            margin-bottom: 10px;
                        }
                        .lweekstats{
                            display: flex;
                            p{
                                font-size: 12px;
                                margin-right: 20px;
                                &:last-child{
                                    color: rgb(29, 255, 168);
                                }
                            }
                            span{
                                background-color: rgb(29, 255, 168);
                            }
                        }
                    }
                    .tbrIcon{
                        position: relative;
                        width: 30%;
                        .MainIctbr{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            height: 40px;
                            width: 40px;
                            fill: var(--theme-color) !important;
                        }
                    }
                }
            }

        }
        .crimeGraphic{
            width: 65%;
            padding: 20px;
            border-radius: 14px;
            border: 1px solid var(--theme-bg-color);
            height: min-content;
            overflow: hidden;
            background-color: var(--theme-bg-color);
            &:hover{
                transform: scale(1.02);
            }
        }
    }
    .toptwo{
        width: 100%;
        display: flex;
        gap: 1rem;
        .ttwoleft{
            width: 34%;
            border-radius: 14px;
            border: 1px solid var(--theme-bg-color);
            padding: 10px;
            padding-bottom: 20px;
            transition: 0.3s ease;
            background-color: var(--theme-bg-color);
            margin-bottom: 1.5rem;
            &:hover{
                transform: scale(1.02);
            }
        }
        .ttworight{
            display: flex;
            gap: 1rem;
            flex-grow: 1;
            .activeadmins{
                width: 50%;
                border-radius: 14px;
                border: 1px solid var(--theme-bg-color);
                padding: 10px;
                padding-bottom: 20px;
                transition: 0.3s ease;
                background-color: var(--theme-bg-color);
                margin-bottom: 1.5rem;
                &:hover{
                    transform: scale(1.02);
                }
            }
            .activeclients{
                width: 50%;
                border-radius: 14px;
                border: 1px solid var(--theme-bg-color);
                padding: 10px;
                padding-bottom: 20px;
                transition: 0.3s ease;
                background-color: var(--theme-bg-color);
                margin-bottom: 1.5rem;
                &:hover{
                    transform: scale(1.02);
                }
            }
        }
    }
}
.chatsection {
  height: calc(100vh - 65px);
  width: 100%;
  padding: 10px;
  position: relative;
}
.chatsection .thechat {
  height: 85%;
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -2%;
}
.chatsection .thechat h2 {
  font-weight: 500;
  font-size: 18px;
  text-indent: -1px;
  line-height: 2;
  text-transform: uppercase;
}
.chatsection .thechat .chatwrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
}
.chatsection .thechat .chatwrapper .usrlist {
  width: 25%;
  height: 100%;
  background-color: var(--theme-bg-color);
  display: flex;
  flex-direction: column;
}
.chatsection .thechat .chatwrapper .usrlist .chatcategory {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border-bottom: 1px solid #808080;
  min-height: 60px;
  height: 60px;
}
.chatsection .thechat .chatwrapper .usrlist .chatcategory .catbtn {
  background: #2ac6ff;
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
  flex-grow: 1;
  opacity: 1;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  padding-bottom: 3px;
}
.chatsection .thechat .chatwrapper .usrlist .chatcategory .inactivecatbtn {
  letter-spacing: 1.6px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--theme-bg-color);
  min-width: 40%;
  transition: 0.3s;
  opacity: 1;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists {
  flex-grow: 1;
  overflow-y: scroll;
  width: 100%;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists::-webkit-scrollbar {
  width: 4px;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3019607843);
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists::-webkit-scrollbar-thumb {
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #808080;
  cursor: pointer;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone:hover {
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldp {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  outline: none;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldp .chldppd {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldp .chldppd img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldp .chldonline {
  position: absolute;
  right: 2px;
  bottom: 4px;
  padding: 6px;
  background-color: #28e428;
  border-radius: 50%;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldinfo {
  flex-grow: 1;
  max-width: 73% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldinfo .chldmeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldinfo .chldmeta .chldmetaname {
  flex-grow: 1;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #c0c0f5;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldinfo .chldmeta .chldmetalsttime {
  display: block;
  font-size: 11px;
  color: #b6c1dd;
}
.chatsection .thechat .chatwrapper .usrlist .chatunlists .chatone .chldinfo .chldpreview p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 14px;
  margin-bottom: 0;
  color: #a8bce0;
  line-height: 1.35;
}
.chatsection .thechat .chatwrapper .chatbox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  height: 100%;
  background-color: var(--theme-bg-color);
  position: relative;
  width: 50%;
}
.chatsection .thechat .chatwrapper .chatbox::-webkit-scrollbar {
  width: 0px;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .chatbox .chatheader {
  display: flex;
  align-content: center;
  padding: 10px;
  border-bottom: 1px solid #808080;
  min-height: 60px;
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--theme-bg-color);
  z-index: 999;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft {
  display: flex;
  padding-left: 10px;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chldp {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  outline: none;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chldp .chldppd {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chldp .chldppd img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chldp .chldonline {
  position: absolute;
  right: -2px;
  bottom: 3px;
  padding: 6px;
  background-color: var(--theme-bg-color);
  border-radius: 50%;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chltexts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chltexts h3 {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #c0c0f5;
}
.chatsection .thechat .chatwrapper .chatbox .chatheader .chleft .chltexts span {
  font-size: 14px;
  margin-bottom: 0;
  color: #a8bce0;
  line-height: 1.35;
}
.chatsection .thechat .chatwrapper .chatbox .chatplay {
  flex-grow: 1;
  width: 100%;
  max-height: calc(100% - 132px);
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .chatbox .chatplay .playmessages {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: scroll;
}
.chatsection .thechat .chatwrapper .chatbox .chatplay .playmessages::-webkit-scrollbar {
  width: 3px;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .chatbox .chatplay .emojidrawer {
  width: auto;
  background-color: var(--theme-bg-color);
  overflow: hidden;
  display: block;
  position: fixed;
  bottom: 0;
}
.chatsection .thechat .chatwrapper .chatbox .chatplay .emojidrawer .emoji-picker-react {
  box-shadow: none !important;
}
.chatsection .thechat .chatwrapper .chatbox .chatplay .hideemojidrawer {
  height: 0;
  -webkit-animation-name: slideOut;
          animation-name: slideOut;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
@-webkit-keyframes slideOut {
  0% {
    height: auto;
  }
  100% {
    height: 0;
  }
}
@keyframes slideOut {
  0% {
    height: auto;
  }
  100% {
    height: 0;
  }
}
.chatsection .thechat .chatwrapper .chatbox .chatbar {
  border-top: 1px solid #808080;
  position: -webkit-sticky;
  position: sticky;
  bottom: -2px;
  padding: 10px 0;
  width: 100%;
  background-color: var(--theme-bg-color);
  z-index: 999;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar {
  height: 100%;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform {
  height: 100%;
  display: flex;
  align-items: center;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .iccs {
  padding-left: 10px;
  padding-top: 5px;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .iccs .smileyface {
  height: 20px;
  width: 25px;
  fill: #24d568;
  cursor: pointer;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .chatinput {
  outline: none;
  border: 1px solid #a8bce0;
  border-radius: 5px;
  padding: 10px;
  flex-grow: 1;
  background-color: var(--theme-bg-color);
  margin: 0 10px;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  visibility: visible;
  color: #d1d7db;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  overflow-wrap: break-word;
  min-height: 50px;
  max-height: 200px;
  max-width: 100%;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .chatinput::-webkit-scrollbar {
  width: 0px;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .sendsec {
  padding-right: 10px;
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .sendsec button {
  outline: none;
  border: none;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .chatbox .chatbar .typebar .chatform .sendsec button .apticon {
  height: 25px;
  width: 25px;
  fill: #78eba4;
  cursor: pointer;
}
.chatsection .thechat .chatwrapper .mypinfo {
  width: 25%;
  height: 100%;
  overflow-y: scroll;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .mypinfo::-webkit-scrollbar {
  width: 3px;
  background-color: var(--theme-bg-color);
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodp {
  padding: 10% 0;
  border-bottom: 1px solid #808080;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodp .ipinfoprofile {
  height: 180px;
  width: 180px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodp .ipinfoprofile img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodp .ipinfoprofiledet {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails {
  padding: 20px;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails h2 {
  text-transform: capitalize;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails .detipone .dettip {
  display: flex;
  align-items: center;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails .detipone .dettip .dettic {
  height: 25px;
  width: 25px;
  fill: #2ac6ff;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails .detipone .dettip div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails .detipone .dettip div p {
  font-size: 12px;
}
.chatsection .thechat .chatwrapper .mypinfo .ipinfodetails .detipone .dettip div p:nth-child(1) {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: -5px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* M E S S A G E S */
.chat {
  list-style: none;
  background: none;
  margin: 0;
}
.chat .messecurity {
  padding: 5px;
  background-color: var(--theme-bg-color);
  width: 50%;
  margin: 10px auto;
  align-self: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.chat .messecurity p {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 1.2;
  text-shadow: none;
  color: #ffd279;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  vertical-align: initial;
  text-align: center !important;
}
.chat li {
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
}
.chat .other:before {
  content: "";
  position: relative;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  border: 5px solid #eef8ff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.chat .other .msg {
  border-top-left-radius: 0px;
  box-shadow: -1px 2px 0px #c1cbcd;
}
.chat .self {
  justify-content: flex-end;
  align-items: flex-end;
}
.chat .self:after {
  content: "";
  position: relative;
  display: inline-block;
  float: right;
  bottom: 0px;
  right: 0px;
  width: 0px;
  height: 0px;
  border: 5px solid #eef8ff;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 0px 2px 0px #c1cbcd;
}
.chat .self .msg {
  border-bottom-right-radius: 0px;
  box-shadow: 1px 2px 0px #c1cbcd;
}
.chat .notification {
  position: relative;
  display: inherit;
  text-align: center;
  font-size: 12px;
  color: #ffd279;
  background-color: var(--theme-bg-color);
  line-height: 30px;
  border-radius: 5px;
  margin: 7px 45%;
  text-transform: uppercase;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px -1px 0px rgba(0, 0, 0, 0.05), inset 0px 1px 0px rgba(255, 255, 255, 0.02), inset 0px -1px 0px rgba(255, 255, 255, 0.02);
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(255, 255, 255, 0.05);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s cubic-bezier(0.565, -0.26, 0.255, 1.41);
}
.chat .notification time {
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 11px;
  padding: 8px;
  border-radius: 100px;
  background: #252C33;
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.02), inset 0px 0px 1px rgba(27, 35, 42, 0.1);
  height: 1px;
  line-height: 0px;
  color: rgba(255, 255, 255, 0.1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: all 0.2s cubic-bezier(0.565, -0.26, 0.255, 1.41);
}

.msg {
  background: #eef8ff;
  min-width: 50px;
  max-width: 60%;
  min-width: 20%;
  padding: 5px 7px;
  padding-bottom: 10px;
  border-radius: 2px;
  position: relative;
}
.msg .admin {
  display: inline-block;
  font-size: 7px;
  font-weight: 300;
  color: #6aea96;
  padding: 2px;
  border-radius: 3px;
  border: solid 1px #6aea96;
  background-color: var(--theme-bg-color);
  margin-left: 5px;
  float: right;
}
.msg p {
  font-size: 13px;
  margin: 0 0 2px 0;
  color: #777;
  transition: all 0.2s ease;
  word-break: break-all;
}
.msg img {
  position: relative;
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #eee;
  transition: all 0.8s cubic-bezier(0.565, -0.26, 0.255, 1.41);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.msg time {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.35);
  margin-top: 3px;
  position: absolute;
  right: 15px;
  bottom: -2px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

emoji {
  display: inline-block;
  height: 18px;
  width: 18px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -7px;
  margin-left: 2px;
  margin-right: 2px;
  transform: translate3d(0px, 4px, 0px);
}

emoji.please {
  background-image: url(https://imgur.com/ftowh0s.png);
}

emoji.lmao {
  background-image: url(https://i.imgur.com/MllSy5N.png);
}

emoji.happy {
  background-image: url(https://imgur.com/5WUpcPZ.png);
}

emoji.pizza {
  background-image: url(https://imgur.com/voEvJld.png);
}

emoji.cryalot {
  background-image: url(https://i.imgur.com/UUrRRo6.png);
}

emoji.books {
  background-image: url(https://i.imgur.com/UjZLf1R.png);
}

emoji.moai {
  background-image: url(https://imgur.com/uSpaYy8.png);
}

emoji.suffocated {
  background-image: url(https://i.imgur.com/jfTyB5F.png);
}

emoji.scream {
  background-image: url(https://i.imgur.com/tOLNJgg.png);
}

emoji.hearth_blue {
  background-image: url(https://i.imgur.com/gR9juts.png);
}

emoji.funny {
  background-image: url(https://i.imgur.com/qKia58V.png);
}

emoji.shit {
  background-image: url(https://i.imgur.com/H5Jba8r.png);
}

@-webikt-keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
/* R E S P O N S I V E   C O N F I G U R A T I O N */
@media screen and (max-width: 750px) {
  ::-webkit-scrollbar {
    display: none;
  }
  .chat {
    margin-bottom: 55px;
  }
  .msg p {
    font-size: 11px;
  }
  .msg .user {
    font-size: 13px;
  }
  .msg img {
    width: 300px;
  }
  .chat .notification {
    font-size: 12px;
    margin: 7px 30%;
    width: 40%;
  }
  .chat .day {
    font-size: 11px;
  }
  .emojis {
    width: 25%;
  }
  .send {
    width: 25%;
  }
}
@media screen and (max-width: 550px) {
  .msg p {
    max-width: 250px;
  }
  .msg img {
    width: 200px;
  }
  .chat .notification {
    font-size: 12px;
    margin: 7px 0px;
    width: 100%;
    border-radius: 0px;
  }
  .chat .notification time {
    right: 10px;
  }
}
.widthzero {
  width: 0 !important;
  flex-grow: unset !important;
}

.prflexhalf {
  width: 50% !important;
}/*# sourceMappingURL=chat.css.map */
.sidebar {
  height: 100vh;
  overflow: hidden;
  background-color: var(--theme-bg-color);
  border-right: 1px solid var(--theme-bg-color);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.sidebar .sideWrapper .sideBrand {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 10px;
}
.sidebar .sideWrapper .sideBrand h2 {
  text-transform: uppercase;
}
.sidebar .sideWrapper .sidenav {
  margin-top: 60px;
}
.sidebar .sideWrapper .sidenav .unlistnav .side-link {
  padding: 5px 20px;
  display: flex;
  align-items: center !important;
  cursor: pointer;
  width: calc(100% - 40px);
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 10px;
}
.sidebar .sideWrapper .sidenav .unlistnav .side-link .navIcs {
  height: 25px;
  width: 25px;
  margin-right: 30px;
  fill: var(--color);
}
.sidebar .sideWrapper .sidenav .unlistnav .side-link:hover {
  background-color: var(--hover-menu-bg);
}
.sidebar .sideWrapper .sidenav .unlistnav .active {
  background-color: var(--hover-menu-bg);
}

.hside {
  -webkit-animation-name: hslide;
          animation-name: hslide;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  width: 0;
  overflow: hidden;
}

.shside {
  -webkit-animation-name: shslide;
          animation-name: shslide;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  width: 250px;
}

@-webkit-keyframes shslide {
  from {
    width: 0;
  }
  to {
    width: 250px;
  }
}

@keyframes shslide {
  from {
    width: 0;
  }
  to {
    width: 250px;
  }
}
@-webkit-keyframes hslide {
  from {
    width: 250px;
  }
  to {
    width: 0px;
  }
}
@keyframes hslide {
  from {
    width: 250px;
  }
  to {
    width: 0px;
  }
}/*# sourceMappingURL=sidebar.css.map */
.header .container .hFlex {
  display: flex;
  align-items: flex-start;
}
.header .container .hFlex .contarea {
  flex-grow: 1;
}
.header .container .hFlex .contarea .nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  height: 65px;
  background-color: var(--theme-bg-color);
  border-bottom: 1px solid var(--theme-bg-color);
  padding: 0 20px;
  position: relative;
}
.header .container .hFlex .contarea .nav .left {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
}
.header .container .hFlex .contarea .nav .left .navControls {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4862745098);
  position: relative;
  margin-right: 20px;
}
.header .container .hFlex .contarea .nav .left .navControls .navBars {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
}
.header .container .hFlex .contarea .nav .left .searchForm {
  flex-grow: 1;
  position: relative;
}
.header .container .hFlex .contarea .nav .left .searchForm .searchInput {
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  color: #000000;
}
.header .container .hFlex .contarea .nav .left .searchForm .searchformbtn {
  outline: none;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 30%;
  top: 10px;
}
.header .container .hFlex .contarea .nav .left .searchForm .searchformbtn .searchLens {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.header .container .hFlex .contarea .nav .right {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 2%;
}
.header .container .hFlex .contarea .nav .right .themeControls {
  position: relative;
}
.header .container .hFlex .contarea .nav .right .themeControls .thmoon, .header .container .hFlex .contarea .nav .right .themeControls .thsun {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 30px;
  stroke: var(--color);
}
.header .container .hFlex .contarea .nav .right .msgIc, .header .container .hFlex .contarea .nav .right .bellIc, .header .container .hFlex .contarea .nav .right svg, .header .container .hFlex .contarea .nav .right .themeControls {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 30px;
  stroke: var(--color);
}
.header .container .hFlex .contarea .nav .right .notifwrapper {
  position: relative;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 30px;
}
.header .container .hFlex .contarea .nav .right .notifwrapper .bellIc, .header .container .hFlex .contarea .nav .right .notifwrapper .msgIc {
  fill: var(--color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header .container .hFlex .contarea .nav .right .notifwrapper .notfBars {
  background-color: #ff0000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
}
.header .container .hFlex .contarea .nav .right .notifwrapper .notfBars div {
  position: relative;
  width: 100%;
  height: 100%;
}
.header .container .hFlex .contarea .nav .right .notifwrapper .notfBars div span {
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
}
.header .container .hFlex .contarea .nav .right .notifwrapper .hidenofifBars {
  display: none;
}
.header .container .hFlex .contarea .nav .right .havatar {
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}
.header .container .hFlex .contarea .nav .right .havatar .avatarHolder {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  fill: var(--color);
}
.header .container .hFlex .contarea .hidenavDropdown {
  display: none;
}
.header .container .hFlex .contarea .navDropDown {
  position: absolute;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  max-height: calc(100vh - 65px);
  width: 250px;
  right: 100px;
  z-index: 1000;
  background-color: #3d3d3d;
  border-radius: 5px;
}
.header .container .hFlex .contarea .navDropDown ul {
  overflow: hidden;
}
.header .container .hFlex .contarea .navDropDown ul li {
  cursor: pointer;
  padding: 5px;
}
.header .container .hFlex .contarea .navDropDown ul li:hover {
  background-color: #646464;
}
.header .container .hFlex .contarea .navDropDown ul li h2 {
  font-size: 15px;
}
.header .container .hFlex .contarea .navDropDown ul li p {
  font-size: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
}
.header .container .hFlex .contarea .playarea {
  height: calc(100vh - 65px);
  overflow-y: scroll;
}
.header .container .hFlex .contarea .playarea::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(47, 50, 57, 0);
}/*# sourceMappingURL=header.css.map */